import { isMobile } from "web3modal";


export const addTokenToMetamask = async (
  { tokenAddress, tokenSymbol, tokenDecimals, tokenImage },
  callback
) => {
  try {
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      callback({
        status: "ADD_TOKEN_SUCCESS",
        data: wasAdded,
      });
      console.log("Thanks for your interest!");
    } else {
      callback({
        status: "ADD_TOKEN_FAILS",
        data: wasAdded,
      });
      // console.log("Your loss!");
    }
  } catch (error) {
    callback({
      status: "ADD_TOKEN_FAILS",
      data: null,
    });
    console.log(error);
  }
};



export const AddChainToMetaMask = async (param) => {


  if (window.ethereum) {
    try {
      // check if the chain to connect to is installed
      const result = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{
          chainId: param.chainId
        }]
      });
    } catch (switchError) {
      if (isMobile()) {
        const errorCode = switchError.data?.originalError?.code
        if (errorCode && errorCode === 4902) {
          try {
            const result = await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [param]
            });
            } catch (addError) {
              console.error(addError);
            }
        }
      } else {
        if (switchError.code === 4902) {
          try {
            const result = await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [param]
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }
      console.error(switchError);
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
    alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
  } 
  
}
