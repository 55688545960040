import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ACTION_CONST, ROUTES, ACTION_STATUS } from "../../constants";
import { BSC_EXPLORER, defaultChainId } from "../../_configs";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { useBnbBalance, useIsConnectWallet, useStakingInfo, useStakingWalletInfo, useWeb3Utils } from "../../shared/components/hook/useSate";
import { useNativeCoin, useNetWork } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";

const UnStakingPage = () => {
    const dispatch = useDispatch();
    const { account, chainId, library } = useActiveWeb3React();

    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const isConnectWallet = useIsConnectWallet()
    const walletUtils = useWeb3Utils();
    const stakingInfo = useStakingInfo();
    const stakingWalletInfo = useStakingWalletInfo();
    const bnbBalance = useBnbBalance()
    const nativeCoin = useNativeCoin(chainId) || 'PLS';
    const netWork = useNetWork(chainId);

    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    const [stakedDuration, setStakedDuration] = useState(0);
    const [totalPctFee, setTotalPctFee] = useState(0);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('0');
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    //enable unstake button
    useEffect(() => {
        if (unStakeCurrentStep === 2) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
        if (stakingInfo && stakingWalletInfo["stakedDuration"]) {
            setStakedDuration(stakingWalletInfo["stakedDuration"]);
            setTotalPctFee(stakingWalletInfo["totalPctFee"] / 100);
        }
    }, [acceptTerm, isConnectWallet, bnbBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 4) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    // console.log(data);
                    if (data.status === ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);

                        setUnStakeCurrentStep(5);

                    }
                    if (data.status === ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "INIT Unstake fails"
                        })
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }


    const submitDone = () => {
        setUnStakeAmount('0');
        setIsMaxAmount(true);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
    }

    return (
        <StakingPortalPage selectedTab={2}>
            <div className="tab-pane fade show active pb-4" id="unstake" role="tabpanel" aria-labelledby="unstake-tab">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                {/* your steps here */}
                                <div className={`step ${unStakeCurrentStep >= 1 ? 'active' : ''}`} id="unStakeButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src={unStakeCurrentStep >= 1 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label first">Warning</span>
                                    </button>
                                </div>
                                <div className={`line ${unStakeCurrentStep >= 2 ? 'active' : ''}`} id="unStakeLineStep1" />
                                <div className={`step ${unStakeCurrentStep >= 2 ? 'active' : ''}`} id="unStakeButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src={unStakeCurrentStep >= 2 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label">Checkpoints</span>
                                    </button>
                                </div>
                                <div className={`line ${unStakeCurrentStep >= 3 ? 'active' : ''}`} id="unStakeLineStep2" />
                                <div className={`step ${unStakeCurrentStep >= 3 ? 'active' : ''}`} id="unStakeButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src={unStakeCurrentStep >= 3 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label">Amount to Unstake</span>
                                    </button>
                                </div>
                                <div className={`line ${unStakeCurrentStep >= 4 ? 'active' : ''}`} id="unStakeLineStep3" />
                                <div className={`step ${unStakeCurrentStep >= 4 ? 'active' : ''}`} id="unStakeButtonStep4">
                                    <button type="button" className="step-trigger">
                                        <img src={unStakeCurrentStep >= 4 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label">Initialize Unstake</span>
                                    </button>
                                </div>
                                <div className={`line ${unStakeCurrentStep >= 5 ? 'active' : ''}`} id="unStakeLineStep4" />
                                <div className={`step ${unStakeCurrentStep >= 5 ? 'active' : ''}`} id="unStakeButtonStep5">
                                    <button type="button" className="step-trigger">
                                        <img src={unStakeCurrentStep >= 5 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="p-sidebar-card-body">
                                {
                                    unStakeCurrentStep === 1 &&
                                    <div className="bs-stepper-content" id="UnStakeStep1">
                                        <div className="text-center">
                                            <h4 className="mb-4 pd-step-title">Warning</h4>
                                            <div className="step-warning d-flex justify-content-center align-items-center">
                                                <i className="fas fa-exclamation-triangle fa-2x text-warning me-3"></i>
                                                <div className="step-desc text-warning">You may be subject to a fee if you wish to unstake &amp; withdraw your $PLSPAD tokens early.</div>
                                            </div>
                                        </div>

                                        <div className="row justify-content-center">
                                            <div className="col-lg-6 col-md-8">
                                                <div className="unlock__bordered bordered mx-auto mt-5" style={{ maxWidth: '100%', width: '460px' }}>
                                                    <div className="bordered__inner bordered--flex bordered--double_angled bordered--bg_right">
                                                        <div className="bordered__block pb-3 p-2-mb">
                                                            <div className="table-responsive">
                                                                <table className="table text-white mb-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Staked less than 2 weeks</td>
                                                                            <td className="text-end">
                                                                                <h5 className="mb-0">25.00%</h5>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{'Less than 4 weeks'}</td>
                                                                            <td className="text-end">
                                                                                <h5 className="mb-0">15.00%</h5>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{'Less than 6 weeks'}</td>
                                                                            <td className="text-end">
                                                                                <h5 className="mb-0">10.00%</h5>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{'Less than 8 weeks'}</td>
                                                                            <td className="text-end">
                                                                                <h5 className="mb-0">5.00%</h5>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ border: 0 }}>{'8 weeks or more'}</td>
                                                                            <td style={{ border: 0 }} className="text-end">
                                                                                <h5 className="mb-0">0.00%</h5>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <p className="mb-2">{'You have staked for'} [{helpers.convertSecondsToReadableString(stakedDuration)}]. Your current fee is: <b className="text-white">{totalPctFee}%</b></p>
                                            <p>Fees will be deducted at the Withdraw stage. Please click 'Next' if you wish to proceed.</p>
                                        </div>
                                    </div>
                                }

                                {
                                    unStakeCurrentStep === 2 &&
                                    <div className="bs-stepper-content" id="UnStakeStep2">
                                        <div className="text-center mb-5">
                                            <h4 className="mb-2 pb-1 pd-step-title">Checkpoints</h4>
                                            <p className="step-desc">The following conditions must be met to proceed:</p>
                                        </div>

                                        <div className="grant__bordered bordered mb-0">
                                            <div className="row pd-card-steps">
                                                <div className="col-xl-3 col-md-6">
                                                    <div className={`pd-card-step ${isConnectWallet ? 'selected' : ''}`} >
                                                        <div className="p-sidebar-card-title">Connected MetaMask</div>
                                                        <div className="p-sidebar-card-body mt-2">
                                                            If not connected, click the "Connect Wallet" button in the top right corner
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-md-6">
                                                    <div className={`pd-card-step ${parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? 'selected' : ''}`} >
                                                        <div className="p-sidebar-card-title">Have an active $PLSPAD stake</div>
                                                        <div className="p-sidebar-card-body mt-2">
                                                            You currently have {helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} $PLSPAD staked
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-md-6">
                                                    <div className={`pd-card-step ${bnbBalance > 0 ? 'selected' : ''}`} >
                                                        <div className="p-sidebar-card-title">{nativeCoin} available</div>
                                                        <div className="p-sidebar-card-body mt-2">
                                                            {nativeCoin} is required to pay transaction fees on the {netWork}.<br />
                                                            {nativeCoin} Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-md-6">
                                                    <div className={`pd-card-step last ${stakingWalletInfo["withdrawTimestamp"] === 0 ? 'selected' : ''}`} >
                                                        <div className="p-sidebar-card-title">Eligible to stake</div>
                                                        <div className="p-sidebar-card-body mt-2">
                                                            You cannot stake if you have an active $PLSPAD unstake/withdrawal request
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 mb-3 text-center">
                                            <div className="form-check">
                                                <input className="form-check-input float-none me-1"
                                                    type="checkbox" defaultValue id="flexCheckDefault"
                                                    onChange={() => setAcceptTerm(!acceptTerm)} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    &nbsp;I have read the&nbsp;
                                                    <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    unStakeCurrentStep === 3 &&
                                    <div className="bs-stepper-content" id="UnStakeStep3">
                                        <div className="text-center">
                                            <h4 className="mb-2 pb-1 pd-step-title">Amount to Unstake &amp; Withdraw</h4>
                                            <p className="step-desc text-white">Please enter the amount of $PLSPAD you want to unstake</p>
                                            <p className="step-desc">Any fees applied at Unstake &amp; Withdraw stage will be based on the date you last staked.</p>
                                            <div className="mx-auto" style={{ maxWidth: '100%', width: '400px' }}>
                                                <div className="p-form-group">
                                                    <label className="form-label p-main-text">Amount</label>
                                                    <div className="input-group pd-input-amount">
                                                        <input type="number" className="form-control" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                                                        <button type="button" className="btn-pd" onClick={handleMaxButtonClick}>MAX</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    unStakeCurrentStep === 4 &&
                                    <div className="bs-stepper-content" id="UnStakeStep4">
                                        <div className="text-center">
                                            <h4 className="mb-4 pd-step-title">Confirm Unstaking Process</h4>
                                            <p className="step-desc">In this step, you initiate the unstaking process.</p>
                                        </div>
                                    </div>
                                }

                                {
                                    unStakeCurrentStep === 5 &&
                                    <div className="bs-stepper-content" id="UnStakeStep5">
                                        <div className="text-center">
                                            <div className="icon-gradient">
                                                <i className="mdi mdi-shield-check-outline" style={{ fontSize: '70px', lineHeight: 1.3 }} />
                                            </div>
                                            <h4 className="pd-step-title">Confirmed</h4>
                                            <p className="step-desc">
                                                You have initiated the unstaking process.<br />
                                                If desired, you may check {netWork} to confirm the transaction.
                                            </p>
                                            <p><a className="p-address" href={`${BSC_EXPLORER[chainId]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 mt-4 text-center pd-step-buttons">
                    {!isSubmitOK ?
                        <div className="d-flex align-items-center justify-content-center" style={{ gap: '20px' }}>
                            <button onClick={() => unStakeBack()} type="button" disabled={!enableUnStakeBtn || unStakeCurrentStep === 1} className="btn-pd">
                                <span><i className="mdi mdi-arrow-left me-2" />Previous</span>
                            </button>
                            <button onClick={() => unStakeNext()} type="button" disabled={!enableUnStakeBtn || unStakeCurrentStep === 5} className="btn-pd">
                                <span>Next<i className="mdi mdi-arrow-right ms-2" /></span>
                            </button>
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-center">
                            <button onClick={() => submitDone()} type="button" className="btn-pd">
                                <span>Done</span>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </StakingPortalPage>
    );
};

export default UnStakingPage;
