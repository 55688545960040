import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIsConnectWallet, useSubmit, useWeb3Utils } from "../components/hook/useSate";
import { helpers } from "../utils";
import { useActiveWeb3React } from "../../hook";
import { useLatestBlockNumber } from "../../hook/useState";
import { ACTION_CONST, ACTION_STATUS } from "../../constants";

const RightBar = () => {

  const dispatch = useDispatch();
  const {  account } = useActiveWeb3React();
  const isConnectWallet = useIsConnectWallet();
  const walletUtils = useWeb3Utils()
  const [stakedAmount, setStakedAmount] = useState('0');
  // const [unstakedAmount, setUnstakedAmount] = useState('0');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);
  const submitOK = useSubmit();
  const latestBlock = useLatestBlockNumber();




  useEffect(() => {
    if (walletUtils && account) {
      walletUtils.getStakingInfoWallet().then(data => {
        setStakedAmount(data.stakedAmount);
        // setUnstakedAmount(data.unstakedAmount);
        setRewardAmount(data.rewardAmount);
        const now = new Date().getTime();

        if (data.withdrawTimestamp > now) {
          setWithdrawTimestamp(data.withdrawTimestamp);
        }
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
    }

  }, [walletUtils, account, submitOK, dispatch, withdrawTimestamp, latestBlock])


  //handle button withdraw click 
  const handleExcWithDraw = () => {
    if (walletUtils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingExecuteWithdrawRewards(data => {
        if (data.status === ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: "Withdraw stake successfully"
          })

        }
        if (data.status === ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: "Withdraw stake fail"
          })
        }
      })
    }
    //to do somrthing
  }

  //stake reward
  const handleExStakeRewards = async () => {
    if (walletUtils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingRewards(data => {
        if (data.status === ACTION_STATUS.STAKING_REWARDS_SUCCESS) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: "Stake rewards successfully"
          })

        }
        if (data.status === ACTION_STATUS.STAKING_REWARDS_FAIL) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: "Stake rewards fail"
          })
        }
      })
    }
  }

  return (
    <div className="row gx-xl-5">
      <div className="col-md-12">
        <div className="pd-card pd-card-4">
          <div className="pd-card-title">Your Staked</div>
          <div className="pd-card-body">
            <span className="pd-card-value">{helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)}</span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="pd-card pd-card-5">
          <div className="pd-card-title">Your Rewards</div>
          <div className="pd-card-body">
            <div className="d-flex w-100 flex-wrap justify-content-between" style={{ gap: '10px' }}>
              <span className="pd-card-value">{helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 4)}</span>
              <div className="pd-card-action d-flex flex-wrap" style={{ gap: '10px' }}>
                <button type="button"
                  disabled={!isConnectWallet || parseFloat(rewardAmount) === 0}
                  onClick={() => handleExStakeRewards()}
                  className="btn-pd btn-pd-sm">
                  <span>Stake</span>
                </button>

                <button type="button"
                  disabled={!isConnectWallet || parseFloat(rewardAmount) === 0}
                  onClick={() => handleExcWithDraw()}
                  className="btn-pd btn-pd-sm">
                  <span>Withdraw</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightBar;
