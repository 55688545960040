import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getStakingContractInfo } from "../utils/bscHelpers";
import RightBar from "./RightBar";
import { changeTab } from "../../redux/action/app";
import { useSubmit, useStakingInfo } from "../components/hook/useSate";
import { helpers } from "../utils";
import { get } from "lodash";
import { useLatestBlockNumber } from "../../hook/useState";
import { ACTION_CONST } from "../../constants";
import { useActiveWeb3React } from "../../hook";
import Web3 from "web3";

const StakingPortalPage = ({ selectedTab, children }) => {
    const dispatch = useDispatch();
    const {chainId} = useActiveWeb3React()
    const stakingInfo = useStakingInfo();

    const [networkStaker, setNetworkStaker] = useState("PulseChain")
    const stakerCount = get(stakingInfo, 'stakerCount', 0);
    const apyPercentage = get(stakingInfo, 'apyPercentage', 0);
    const totalStakedAmount = get(stakingInfo, 'totalStakedAmount', 0);
    const submitOK = useSubmit()

    useEffect(() => {
        dispatch(changeTab(1));
    }, [dispatch])

    useEffect(()=>{
        if(chainId == 106){
            setNetworkStaker("Velas")
        }
        else if(chainId == 369){
            setNetworkStaker("PulseChain")
        }
    },[chainId])

    useEffect(() => {
        getStakingContractInfo(chainId).then(data => {
            dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
        })
    }, [dispatch, submitOK]);


    return (
        <>
            {
                stakingInfo["isStakingPaused"] ?
                <div className="container text-center d-md-block d-none">
                    <span className="pp-banner-top">
                        <i className="mdi mdi-alert-outline fs-20 me-2 text-danger"></i> All staking functions are temporarily paused. Please check back again later.
                    </span>
                </div> : <></>
            }
            <div className="container">
                {
                    stakingInfo["isStakingPaused"] ?
                    <div className="container text-center d-md-none d-block pp-notice">
                        <span className="pp-banner-top">
                            <i className="mdi mdi-alert-outline fs-20 me-2 text-danger"></i> All staking functions are temporarily paused. Please check back again later.
                        </span>
                    </div> : <></>
                }
                <div className="p-content-tabs">
                    <div className="mb-lg-5 py-5">
                        {selectedTab == 1 && <>
                            <h4 className="p-tab-title mb-0">Stake your <span>$PLSPAD</span></h4>
                            <div className="div-outline">
                                <span>Please connect your wallet to PulseChain or Velas Networks to participate in staking</span>
                            </div>
                        </>}
                        {selectedTab == 2 && <h4 className="p-tab-title mb-0">Unstake &amp; Withdraw your <span>$PLSPAD</span></h4>}
                        {selectedTab == 3 && <h4 className="p-tab-title mb-0">Withdraw your <span>$PLSPAD</span></h4>}

                    </div>
                    
                    {/* <div className="text-center">
                        <span className="">
                            Please connect your wallet to PulseChain or Velas Networks to participate in staking
                        </span>
                    </div> */}
                    
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="pd-card pd-card-1">
                                        <div className="pd-card-title">Number of Stakers ({networkStaker})</div>
                                        <div className="pd-card-body">
                                            <span className="pd-card-value">{stakerCount}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="pd-card pd-card-2">
                                        <div className="pd-card-title">Total <span>$PLSPAD</span> Staked</div>
                                        <div className="pd-card-body">
                                            <span className="pd-card-value">{helpers.formatNumberDownRound(totalStakedAmount, 2)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="pd-card pd-card-3">
                                        <div className="pd-card-title">APY</div>
                                        <div className="pd-card-body">
                                            <span className="pd-card-value">{helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <RightBar />
                        </div>
                    </div>
                    <div className="tab-content pt-5 mt-5" id="myTabContent">
                        {/* <img src="/images/uz7.svg" className="tab-content-bg left-bg"/> */}
                        <img src="/images/uz8.svg" className="tab-content-bg right-bg"/>
                        {children}
                    </div>
                </div>
            </div>

            {/* <TransactionConfirmationModal
                isOpen={showModal}
                attemptingTxn="########"
                hash={undefined}
                pendingText="Loading..."
                children={undefined}
                onDismiss={() => setShowModal(false)}
            /> */}
        </>
    );
};

export default StakingPortalPage;
