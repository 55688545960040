import Web3 from "web3";

export const MODE = process.env.REACT_APP_MODE || "TESTNET";

export const BSC_EXPLORER = {
    106: "https://evmexplorer.velas.com",
    // 111: "https://evmexplorer.testnet.velas.com",
    369: "https://otter.pulsechain.com",
};



export const STAKING_CONTRACT_ADDRESS = {
    106: "0x796f012052AB9AF44c81D53A1A8368D4D5cff8FB",
    369: "0x09DBacf80aD0805b5D263461407A732f08dCD8f6"

};


    




export const POOL_INTERVAL = 15000;


export const NODE_URI = {
    106: [ "https://mainnet.velas.com/rpc"],
    369: ["https://rpc.pulsechain.com"]
        // 1: ['https://mainnet.infura.io/v3/e6d8a9c75b2e4e8c9d1e4c57446ffded']
};

export const KYC_BACK_END_URI = "https://bscpad.com";

export const chainIdsSupport = [106, 369];

export const defaultChainId = 369
    // process.env.REACT_APP_MODE === "MAINNET" ? 56 : 97;

export const showKYC = 1000.0


export const chainList = {
     369 : {
        chainId:  Web3.utils.numberToHex(369),
        rpcUrls: ["https://rpc.pulsechain.com"],
        chainName: "Pulse Chain Mainnet",
        nativeCurrency: {
          name: "Pulse",
          symbol: "PLS",
          decimals: 18
        },
        blockExplorerUrls: ["https://otter.pulsechain.com"]
    },
   106 : {
        chainId: Web3.utils.numberToHex(106),
        rpcUrls: [ "https://mainnet.velas.com/rpc"],
        chainName: "Velas Mainnet",
        nativeCurrency: {
          name: "Velas",
          symbol: "VLX",
          decimals: 18
        },
        blockExplorerUrls: ["https://evmexplorer.velas.com"]
    }

}