import React from 'react';
import { Link } from 'react-router-dom';
import packageJson from '../../../package.json';
import { ROUTES } from '../../constants';
import ConnectWalletModal from "../modals/ConnectWalletModal";
import WalletModal from "../modals/WalletModal";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="pp-footer">
        <div className="container">
          <div className="d-flex justify-content-between pp-footer-content">
            <div> Copyright © {currentYear}. All Rights Reserved by PulsePad</div>
            <div> v{packageJson.version}</div>
            <div className="text-end pp-footer-link">
              <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy </Link>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link target="_blank" to={ROUTES.COOKIES_POLICY}>Cookies Policy </Link>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms of Use </Link>
            </div>
          </div>
        </div>
      </div>
      <ConnectWalletModal />
      <WalletModal/>
      {/* <TransactionConfirmationModal isOpen={openModal} onDismiss={() => setOpenModal(true)} attemptingTxn={true} pendingText="####_PENDING_TEXT" /> */}
    </>
  );
}